import _get from 'lodash/get';
import React from 'react';

type VacancyLinkProps = {
  doc: any;
};

const VacancyLink: React.FC<VacancyLinkProps> = ({ doc }) => {
  return (
    <a
      href={_get(doc, ['node', 'careers_link', 'url'])}
      className="our-brands__link career__link"
      target={_get(doc, ['node', 'careers_link', 'target'])}
    >
      <span className="our-brands__link-arrow"></span>
      <span className="career__link-image"></span>
      <span className="career__link-text">{_get(doc, ['node', 'careers_link_title'])}</span>
    </a>
  );
};

export default VacancyLink;
