import React from 'react';
import { Field, FieldProps } from 'formik';
import TextField from '@material-ui/core/TextField';

interface ICustomTextFieldProps {
  name: string;
  label: string;
  [key: string]: any;
  type?: string;
}

const CustomTextField: React.FC<ICustomTextFieldProps> = ({
  name,
  label,
  type,
  customClass,
  ...props
}) => {
  return (
    <>
      <Field name={name}>
        {({ form: { getFieldProps } }: FieldProps) => (
          <TextField
            fullWidth
            rows={type === 'textarea' ? 3 : undefined}
            label={label}
            multiline={type === 'textarea'}
            type={type ? type : 'text'}
            variant="outlined"
            classes={customClass ? { root: customClass } : {}}
            {...getFieldProps(name)}
            {...props}
          />
        )}
      </Field>
    </>
  );
};

export default CustomTextField;
