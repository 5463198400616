import Images from '@components/Images';
import Layout from '@components/Layout';
import { extractGraphqlData } from '@utils/extractors';
import { linkResolver } from '@utils/Prismic/linkResolver';
import { ENUM_STATIC_MENU_LINKS } from '@utils/types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import React, { useRef } from 'react';
import _get from 'lodash/get';
import ContactForm from '@components/ContactForm';
import VacancyLink from '@components/VacancyLink';
import Image from '@components/Images/Image';
import ScrollLinks, { ScrollLink } from '@components/ScrollLinks';

interface IContactProps {
  data: any;
}

const Contact: React.FC<IContactProps> = ({ data }) => {
  const doc = extractGraphqlData(data, 'allContact_pages');
  const careerDoc = extractGraphqlData(data, 'allSimple_careers_pages');
  const textsDoc = extractGraphqlData(data, 'allSite_textss');
  const { page_title, page_description, canonical_tag, page_og_image } = doc.node.seo[0];
  const emailAddr = RichText.asText(textsDoc.node.email);

  const tradeAccountRef = useRef<HTMLDivElement>(null);
  const workWithUsRef = useRef<HTMLDivElement>(null);
  const supportRef = useRef<HTMLDivElement>(null);

  const scrollLinks: ScrollLink[] = [
    { name: 'Trade Account', key: 'trade-account', ref: tradeAccountRef, scrollOffset: -50 },
    { name: 'Work With Us', key: 'work-with-us', ref: workWithUsRef, scrollOffset: -50 },
    { name: 'Business Support', key: 'business-support', ref: supportRef, scrollOffset: 0 },
  ];

  return (
    <>
      <Layout
        title={page_title ? RichText.asText(page_title) : 'Contact'}
        description={page_description && RichText.asText(page_description)}
        firstLevelPage
        seo={{
          path: ENUM_STATIC_MENU_LINKS.CONTACT,
          canonical: canonical_tag && canonical_tag.url,
          image: page_og_image ? page_og_image : doc.node.images[0].image.url,
        }}
      >
        <div className="contact-page__bg">
          <div className="contact-page">
            <div className="contact-page__content">
              <div className="contact-page__text">
                <div className="anim-title contact-page__upper">
                  <h1 className="contact-page__heading">{RichText.asText(doc.node.heading)}</h1>
                  <h2 className="contact-page__h2">{RichText.asText(doc.node.team_heading)}</h2>
                  {RichText.render(doc.node.team_text, linkResolver)}
                  <ScrollLinks links={scrollLinks} />
                </div>
                <div className="contact-page__mobileonly-image">
                  <Image
                    image={{ image: doc.node.team_image }}
                    extractPath="image"
                    handleRef={null}
                    className="contact-page__mobileonly-image-img"
                  />
                </div>
                <div className="anim-block">
                  <div className="contact-page__account" ref={tradeAccountRef}>
                    <h3 className="contact-page__upperHeading">
                      {RichText.asText(doc.node.trade_account_heading_small)}
                    </h3>
                    <h2 className="contact-page__h2">
                      {RichText.asText(doc.node.trade_account_heading)}
                    </h2>
                    {RichText.render(doc.node.trade_account_text, linkResolver)}
                    <ContactForm />
                  </div>

                  <div className="contact-page__jobs" ref={workWithUsRef}>
                    <h3 className="contact-page__upperHeading">
                      {RichText.asText(doc.node.careers_heading_small)}
                    </h3>
                    <h2 className="contact-page__h2">
                      {RichText.asText(doc.node.careers_heading)}
                    </h2>
                    {RichText.render(doc.node.careers_text, linkResolver)}
                    <VacancyLink doc={careerDoc} />
                  </div>
                </div>
              </div>

              <div className="contact-page__image">
                <Images
                  imageData={[{ image: doc.node.team_image }]}
                  extractPath="image"
                  alt={_get(doc, ['node', 'team_image', 'alt'])}
                  classes={{
                    base: 'anim-image',
                    extend: 'contact-page',
                    wrap: 'contact-page__image-img',
                    class: '',
                  }}
                  width="526"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="contact-page__blackbanner" ref={supportRef}>
          <div className="contact-page__blackbanner-inner">
            <div className="contact-page__blackbanner-text">
              <h3 className="contact-page__upperHeading">
                {RichText.asText(doc.node.support_heading_small)}
              </h3>
              <h2 className="contact-page__h2">{RichText.asText(doc.node.support_heading)}</h2>
              <div className="contact-page__smallNote">
                {RichText.render(doc.node.support_small_note, linkResolver)}
              </div>
              <div className="contact-page__blackbanner-linksText">
                {RichText.render(doc.node.support_links_text, linkResolver)}
              </div>
            </div>
            <div className="contact-page__blackbanner-image">
              <Image
                image={{ image: doc.node.support_image }}
                extractPath="image"
                handleRef={null}
                className="contact-page__blackbanner-image-img"
              />
            </div>
          </div>
        </div>
        <div className="contact-page__footer">
          <div className="contact-page__footer-inner">
            <div className="contact-page__footer-phones">
              <div className="contact-page__footer-phone">
                <span>Sales:</span> <a href={'tel:' + textsDoc.node.sales}>{textsDoc.node.sales}</a>
              </div>
              <div className="contact-page__footer-phone">
                <span>Accounts:</span>{' '}
                <a href={'tel:' + textsDoc.node.accounts}>{textsDoc.node.accounts}</a>
              </div>
            </div>
            <div className="contact-page__footer-address">
              {RichText.render(textsDoc.node.contact_form_address, linkResolver)}
            </div>
            <div className="contact-page__footer-email">
              <a href={'mailto:' + emailAddr}>{emailAddr}</a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query Contact {
    prismic {
      allContact_pages {
        edges {
          node {
            heading
            team_heading
            team_text
            team_image
            trade_account_heading_small
            trade_account_heading
            trade_account_text
            careers_heading_small
            careers_heading
            careers_text
            support_heading_small
            support_heading
            support_small_note
            support_links_text
            support_image
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
      allSimple_careers_pages {
        edges {
          node {
            careers_link_title
            careers_link {
              ... on PRISMIC__ExternalLink {
                url
                target
              }
            }
          }
        }
      }
      allSite_textss {
        edges {
          node {
            sales
            accounts
            contact_form_address
            email
          }
        }
      }
    }
  }
`;

export default Contact;
