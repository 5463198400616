import React from 'react';

export type ScrollLink = {
  name: string;
  key: string;
  ref: React.RefObject<HTMLDivElement>;
  scrollOffset?: number;
};

type ScrollLinksProps = {
  links: ScrollLink[];
};

const ScrollLinks: React.FC<ScrollLinksProps> = ({ links }) => {
  const handleScrollTo = (ref: React.RefObject<HTMLDivElement>, offset: number) => {
    // scroll with 50px offset
    if (ref.current) {
      const y = ref.current.getBoundingClientRect().top + window.scrollY + offset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <div className="scroll-links">
      {links.map(({ key, name, ref, scrollOffset }) => (
        <a
          href={`#${key}`}
          key={key}
          onClick={e => {
            e.preventDefault();
            handleScrollTo(ref, scrollOffset || 0);
          }}
          className="scroll-links-link"
        >
          {name}
        </a>
      ))}
    </div>
  );
};

export default ScrollLinks;
