import CustomTextField from '@components/CustomTextField';
import { CONTACT_EMAIL, GETFORM_CONTACT_URL } from '@utils/constants';
import axios from 'axios';
import { Field, Formik } from 'formik';
import React, { useState } from 'react';

type FormState = 'open' | 'sending' | 'success' | 'error';

type ContactFormValues = {
  name: string;
  business: string;
  email: string;
  phone: string;
  prefferedContact: 'phone' | 'email';
  postalCode: string;
  message: string;
};

const initialValues: ContactFormValues = {
  name: '',
  business: '',
  email: '',
  phone: '',
  prefferedContact: 'email',
  postalCode: '',
  message: '',
};

const validateForm = (values: ContactFormValues) => {
  const errors: Record<string, string> = {};
  for (const [key, value] of Object.entries(values)) {
    // test required
    if (value == '' && key !== 'message') {
      errors[key] = 'required';
      continue;
    }
    if (
      key == 'email' &&
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)
    ) {
      errors.email = 'no_email';
    }
  }
  return errors;
};

const printErrors = (errors: Record<string, string>) => {
  for (const [_, value] of Object.entries(errors)) {
    if (value == 'required') {
      return 'Please fill in all the red-marked fields to continue.';
    }
    if (value == 'no_email') {
      return 'Email address is not in proper format, please fix that.';
    }
  }
};

const ContactForm: React.FC = () => {
  const [formState, setFormState] = useState<FormState>('open');

  async function sendForm(values: ContactFormValues) {
    setFormState('sending');
    // @TODO logic for sending
    try {
      const result = await axios({
        url: GETFORM_CONTACT_URL,
        method: 'post',
        headers: {
          Accept: 'application/json',
        },
        data: values,
      });
      if (result.data && result.data.success) {
        setFormState('success');
      } else {
        console.error('Form submission not successful: ', result.data);
        setFormState('error');
      }
    } catch (err) {
      console.error('Form communication error: ', err);
      setFormState('error');
    }
  }

  return (
    <>
      {formState == 'open' && (
        <>
          <Formik<ContactFormValues>
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              sendForm(values);
            }}
            validate={validateForm}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, errors }) => (
              <form className="form form__contact" onSubmit={handleSubmit}>
                <div className="form__two-fields">
                  <CustomTextField
                    name="name"
                    label="Your Name"
                    customClass="form__field"
                    error={typeof errors.name != 'undefined'}
                  />
                  <CustomTextField
                    name="business"
                    label="Business Name"
                    customClass="form__field"
                    error={typeof errors.business != 'undefined'}
                  />
                </div>
                <div className="form__two-fields form__top_margin">
                  <CustomTextField
                    name="email"
                    label="Email"
                    customClass="form__field"
                    error={typeof errors.email != 'undefined'}
                  />
                  <CustomTextField
                    name="phone"
                    label="Phone"
                    customClass="form__field"
                    error={typeof errors.phone != 'undefined'}
                  />
                </div>
                <div className="form__radios">
                  <label>Preferred contact method</label>
                  <label className="form__radio">
                    <Field type="radio" name="prefferedContact" value="email" />
                    By Email
                  </label>
                  <label className="form__radio">
                    <Field type="radio" name="prefferedContact" value="phone" />
                    By Phone
                  </label>
                </div>
                <div className="form__field-note">
                  <CustomTextField
                    name="postalCode"
                    label="* Postal Code"
                    className="form__field"
                    error={typeof errors.postalCode != 'undefined'}
                  />
                  <span className="note">
                    * Your location will determine eligibility and your local Oak team.
                  </span>
                </div>
                <div className="form__field-message">
                  <CustomTextField
                    name="message"
                    type="textarea"
                    label="Message (Optional)"
                    className="form__field"
                    error={typeof errors.message != 'undefined'}
                  />
                </div>
                <div className="form__button-line">
                  {errors && <div className="form__errors">{printErrors(errors)}</div>}
                  <button className="form__button" type="submit">
                    SUBMIT
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
      {formState == 'sending' && (
        <div className="form__notification">
          <p className="progress">Sending your enquiry...</p>
        </div>
      )}
      {formState == 'error' && (
        <div className="form__notification">
          <p className="error">Error while sending form!</p>
          <p className="error">
            Please contact us via e-mail: <a href={'mailto:' + CONTACT_EMAIL}>{CONTACT_EMAIL}</a>
          </p>
        </div>
      )}
      {formState == 'success' && (
        <div className="form__notification">
          <h3>Submission successful</h3>
          <p>Thank you for your enquiry.</p>
          <p>It has been forwarded to the team and will be dealt with as soon as possible.</p>
          <a onClick={() => setFormState('open')}>Send a new enquiry</a>
        </div>
      )}
    </>
  );
};

export default ContactForm;
